.otp-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-otp-input {
    width: 40px !important;
    height: 45px;
    margin: 5px;
    font-size: 24px;
    text-align: center;
    border: 2px solid #0889c5;
    border: 0;
    box-shadow: none;
    /* border-radius: 8px; */
    /* background-color: #f1f1f1; */
    border-bottom: 2px solid #0889c5;
    outline: none;
}

/* Style the separator */
.otp-separator {
    margin: 2px;
    font-size: 30px;
    color: #ffffff;
}

/* Mobile view customization using media queries */
@media only screen and (max-width: 767px) {
    .otp-separator {
        margin: 1px;
        /* Set a different margin for mobile view */
    }
}

.otp-separator-mobile {
    margin: 1px;
    font-size: 10px;
    color: #1799f0;
}

/* Style for mobile screens */
.otp-container-mobile {
    /* width: 100px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Add specific styles for mobile screens */
}

/* LoginToggle.css */
.toggle-button-group .MuiToggleButton-root {
    color: rgb(255, 255, 255);
    background-color: #96afd5;
    height: 40px; /* Reduced height */
    width: 150px; /* Increased width */
    border-radius: 16px; /* Increased border radius */
    position: relative; /* Required for the pseudo-element positioning */
    overflow: hidden; /* To ensure the pseudo-element doesn't overflow the button */
}

.toggle-button-group .MuiToggleButton-root::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    transition: width 0.3s ease; /* Animation effect */
    z-index: 1; /* Ensure it appears above the button background */
}

.toggle-button-group .MuiToggleButton-root:hover::after {
    width: 0px; /* The width of the side animation on hover */
}

.toggle-button-group .MuiToggleButton-root.Mui-selected {
    background-color: #3265b4;
    color: white;
}

.toggle-button-group .MuiToggleButton-root:hover {
    background-color: #27559d;
}

.radio-group .MuiFormControlLabel-root {
    margin-bottom: 0px; /* Adjust space between radio buttons */
}

.radio-group .MuiRadio-root {
    color: darkblue;
}
.radio-group .MuiFormControlLabel-label {
    font-size: 0.875rem; /* Smaller font size for the labels */
}

.radio-group .Mui-checked {
    color: darkblue !important; /* Ensure the checked color matches */
}
