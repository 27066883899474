/* styles.css */

.search-list {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.search-list-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.search-list-item:hover {
    background-color: #e0e0e0;
}

.search-icon {
    margin-right: 16px;
    color: #3f51b5;
    font-size: 24px;
}

.search-selected {
    background-color: #c5cae9;
}

.search-text-primary {
    font-weight: 500;
    color: #333;
}

.search-text-secondary {
    color: #757575;
}