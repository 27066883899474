.row {
    margin-top: 8px; /* Reduced the top margin */
}

.card {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 8px; /* Reduced padding */
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.button {
    background-color: #00796b;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 4px 8px; /* Reduced padding for the button */
    cursor: pointer;
    margin-top: 4px; /* Less space above the button */
}
.buttonCurrent {
    background-color: #667f97;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 4px 8px; /* Reduced padding for the button */
    cursor: pointer;
    margin-top: 4px; /* Less space above the button */
}

.planTitle {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 4px; /* Reduced spacing below the title */
    padding-top: 8px;
}

.card p {
    margin: 2px 0; /* Minimal vertical spacing between lines */
}

.ribbon {
    display: block;
}

.ribbonNon {
    display: none;
}

/* Specific styles for each plan */
.normalPlan {
    background-color: #f0f0f0;
    color: #000;
}

.silverPlan {
    background-color: #e0f7fa;
    color: #00796b;
}

.goldPlan {
    background-color: #fff8e1;
    color: #ffa600;
}
.commissionData {
    font-size: 1.1rem;
}
.commissionAmount {
    font-weight: bold;
}
.additionalInfo {
    font-size: 0.85rem; /* Slightly smaller font size */
    color: #888; /* Lighter text color */
    margin-left: 4px; /* Small space between the text and the parent element */
    font-style: italic; /* Italicize the text for emphasis */
}
/* Adjust font sizes and paddings for smaller screens */
@media (max-width: 768px) {
    .card {
        padding: 12px;
    }
    .planTitle {
        font-size: 1rem;
    }
    .commissionData {
        font-size: 0.9rem;
    }
}
    