.custom-modal .ant-modal-content {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.custom-modal .modal-content {
    padding: 20px;
    text-align: center;
}

.custom-modal h2 {
    margin-bottom: 10px;
    color: #1890ff;
    /* Ant Design primary color */
}

.custom-modal p {
    color: #333;
}