.formContainer {
    width: 100%;
    margin: 0;
    padding: 0.5rem;
    box-sizing: border-box;
}

.stepContent {
    min-height: 250px;
    padding: 16px;
}

.imageUploadBox {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 1rem 0;
}

.imageUploadBoxHover {
    border-color: #1976d2;
    background-color: rgba(25, 118, 210, 0.04);
}

.agentInfoBox {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0;
    word-break: break-word;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding: 0 0.5rem;
    position: sticky;
    bottom: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.instructions {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0;
}

.radioGroup {
    margin: 1rem 0;
}

.successText {
    color: #2e7d32;
    margin-top: 0.5rem;
}

.stepHeader {
    margin-bottom: 1rem;
    font-size: 1.25rem !important;
}

.stepQuestion {
    margin-bottom: 1rem;
    font-size: 1rem !important;
}

.remarksField {
    margin-top: 1rem;
    width: 100%;
}

.stepperContainer {
    padding: 0.5rem 0;
    overflow-x: auto;
    margin-bottom: 1rem;
}

.stepLabel {
    font-size: 0.75rem !important;
}

.contentCard {
    box-shadow: none !important;
    margin-bottom: 4rem;
}

.cameraIcon {
    font-size: 2.5rem !important;
}

.uploadText {
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.fullWidthButton {
    width: 45%;
}

.stepperContainerMuiStepLabel {
    font-size: 0.75rem;
}

.stepperContainerMuiSvgIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.imageUploadBoxSmall {
    padding: 1rem;
}

.previewContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imagePreview {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.buttonGroupWrapper {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
}

.selectionButton {
    min-width: 120px !important;
    padding: 10px 24px !important;
}

.selectedYes {
    background-color: #4caf50 !important;
    color: white !important;
}

.selectedYesHover {
    background-color: #43a047 !important;
}

.selectedNo {
    background-color: #f44336 !important;
    color: white !important;
}

.selectedNoHover {
    background-color: #e53935 !important;
}

.unselected {
    background-color: #f5f5f5 !important;
    color: #757575 !important;
}

.unselectedHover {
    background-color: #eeeeee !important;
}
