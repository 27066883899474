.accordionContainer {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(to right bottom, #ffffff, #f8f9fa);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.accordion {
    margin-bottom: 12px !important;
    border-radius: 8px !important;
    border: 1px solid #eef2f6 !important;
    background-color: white !important;
    transition: all 0.3s ease !important;
}

.accordion:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
}

.accordionHeader {
    background: linear-gradient(to right, #f8f9fa, #ffffff) !important;
    border-radius: 8px !important;
}

.batchTitle {
    color: #2c3e50 !important;
    font-weight: 600 !important;
    font-size: 1.1rem !important;
}

.tableContainer {
    margin-top: 10px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.table {
    background-color: white;
}

.tableHeader {
    background-color: #f8fafc !important;
}

.tableHeaderCell {
    color: #64748b !important;
    font-weight: 600 !important;
    padding: 16px !important;
    font-size: 0.9rem !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
}

.tableRow {
    transition: background-color 0.2s ease !important;
}

.tableRow:hover {
    background-color: #f8fafc !important;
}

.tableCell {
    padding: 16px !important;
    color: #334155 !important;
    font-size: 0.95rem !important;
}

.amountCell {
    color: #0f766e !important;
    font-weight: 500 !important;
}

.typeCell {
    display: inline-block;
    padding: 4px 12px !important;
    border-radius: 12px;
    font-size: 0.85rem !important;
    font-weight: 500 !important;
}

.typeCredit {
    background-color: #dcfce7;
    color: #166534 !important;
}

.typeDebit {
    background-color: #fee2e2;
    color: #991b1b !important;
}
.createButtonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.createButton {
    padding: 10px 24px !important;
    border-radius: 8px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
    transition: all 0.3s ease !important;
}

.createButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15) !important;
}
.dialog {
    border-radius: 16px !important;
    padding: 16px;
    background: linear-gradient(135deg, #eceff1, #cfd8dc);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #263238;
    text-align: center;
    margin-bottom: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.dataRow {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #b0bec5;
}

.label {
    font-weight: 500;
    color: #37474f;
}

.value {
    font-weight: bold;
    color: #1e88e5;
}

.actions {
    justify-content: center;
    padding: 16px 0;
}

.proceedButton {
    background-color: #1e88e5;
    color: #ffffff;
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 1rem;
    text-transform: none;
}

.openButton {
    background-color: #1e88e5;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 8px;
    text-transform: none;
    font-size: 1rem;
}
.summaryContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 8px 0;
}

.batchTitle {
    font-size: 1rem !important; /* 18px */
    font-weight: 600 !important;
    color: #1e293b !important;
}

.settlementAmount {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #1e293b !important;
    text-align: center;
}

.status {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 1rem !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
}

.pending {
    background-color: #fff7ed;
    color: #ea580c;
}

.completed {
    background-color: #f0fdf4;
    color: #16a34a;
}

.failed {
    background-color: #fef2f2;
    color: #dc2626;
}

/* Make accordion header more prominent */
.accordionHeader {
    /* min-height: 54px !important; */
    background-color: #f8fafc;
}
.actionButtonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 16px;
}

.approveButton {
    background-color: #4caf50;
    color: white;
}

.rejectButton {
    border-color: #f44336;
    color: #f44336;
}
