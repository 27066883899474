.goldenChip {
    position: relative;
    border-radius: 27px; /* Ensure it matches the Chip's borderRadius */
    overflow: hidden;
    border: 2px solid #b07908; /* Initial border color */
    background: #1f1f1f;
}

.goldenChip::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%; /* Adjust width for smoother animation */
    height: 100%;
    border-radius: inherit; /* Ensure it matches the Chip's borderRadius */
    /* background: linear-gradient(90deg, rgba(255, 215, 0, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 215, 0, 0) 100%); */
    animation: wave 1.6s linear infinite;
}

.goldenChip:hover {
    background: linear-gradient(145deg, #1f1f1f, #0071bc); /* Swap the gradient direction */
    border-color: gold;
}

.goldenChip:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%; /* Adjust width for smoother animation */
    height: 100%;
    border-radius: inherit; /* Ensure it matches the Chip's borderRadius */
    background: linear-gradient(90deg, rgba(255, 215, 0, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 215, 0, 0) 100%);
    animation: wave 1.6s linear infinite;
}

.nameText {
    font-weight: bold;
    color: #fff !important; /* Ensure the text color matches the gold border */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Add a slight shadow for better readability */
}

@keyframes wave {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

.crowned-name {
    position: relative;
    display: flex;
    align-items: center;
}
