.wallet-dropdown {
    display: none;
}
.wallet-button {
    display: block; /* Hide button on mobile view */
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .wallet-button {
        display: none; /* Hide button on mobile view */
    }
    .wallet-dropdown {
        display: block;
    }
}

@keyframes wave {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
