/* NearbyAgents.module.css */
.container {
    padding: 1rem 0;
}

.paper {
    padding: 1rem;
}

.title {
    margin-bottom: 2rem;
    color: #1976d2;
    font-weight: 500;
}

.filtersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.formControl {
    min-width: 200px;
}

.textField {
    min-width: 200px;
}

.tableContainer {
    height: 600px;
    width: 100%;
}

.dataGrid {
    background-color: #fff;
}

.verifyButton {
    text-transform: none;
}

@media (max-width: 600px) {
    .filtersContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .formControl,
    .textField {
        width: 100%;
    }

    .paper {
        padding: 1rem;
    }
}
.accordionContainer {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(to right bottom, #ffffff, #1e90c9);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.accordion {
    margin-bottom: 12px !important;
    border-radius: 8px !important;
    border: 1px solid #eef2f6 !important;
    background-color: white !important;
    transition: all 0.3s ease !important;
}

.accordion:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
}

.accordionHeader {
    background: linear-gradient(to right, #f8f9fa, #ffffff) !important;
    border-radius: 8px !important;
}
.filterTitle {
    margin-bottom: 0.4rem;
    color: #1976d2;
    font-weight: 500;
}